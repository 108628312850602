.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.content {
  flex: 1;
  padding: 200px 0;
}

@media (min-width: 500px) {
  .container {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    background-color: #f0f2f5;
  }
}

.top {
  text-align: center;
}

.header {
  height: 44px;
  line-height: 44px;
}

.logo {
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}

.layout_title {
  position: relative;
  top: 2px;
  font-weight: 600;
  font-size: 33px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin-bottom: 40px;
}
