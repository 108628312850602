.basic-layout__logo {
  height: 64px;
  background-color: #1890ff;
  color: #fff;
  text-align: center;
  line-height: 64px;
  font-size: 32px;
}

.basic-layout__trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: #fff;
}

.basic-layout__content {
  margin: 0 16px;
}

.basic-layout__header-right {
  height: 100%;
  float: right;
  overflow: hidden;
}

.basic-layout__header-right-avatar {
  cursor: pointer;
}

.basic-layout__header-right-avatar > .basic-layout__header-username {
  font-weight: bolder;
  color: #ffffff;
  font-style: italic;
  padding: 0 5px;
}
