@import '~antd/dist/antd.css';

* {
  margin: 0;
  padding: 0;
}
html,
body,
#root {
  height: 100%;
}

.colorWeak {
  filter: invert(80%);
}

.ant-layout {
  min-height: 100vh;
}

canvas {
  display: block;
}

ul,
ol {
  list-style: none;
}

.ant-layout-header {
  background-color: #1890ff;
  padding: 0 50px 0 10px;
}
a {
  white-space: nowrap;
}
